import Cookies from "js-cookie";

function PopupAction() {
  console.log("PopupAction running...");
  const popupAction = document.querySelector(".popup-action");

  if (!popupAction) {
    console.warn("No element with .popup-action found");
    return;
  }

  const name = popupAction.dataset.name;
  const expires = parseInt(popupAction.dataset.expires) || 7;

  console.log("Checking cookie:", name, Cookies.get(name));

  if (!Cookies.get(name)) {
    console.log("No cookie found — showing popup");
    popupAction.classList.remove("hidden-menu");
  } else {
    console.log("Cookie exists — keeping popup hidden");
  }

  const closeBtn = popupAction.querySelector(".popup-close");

  if (closeBtn) {
    closeBtn.addEventListener("click", function () {
      console.log("Close button clicked — hiding popup & setting cookie");
      popupAction.classList.add("hidden-menu");
      Cookies.set(name, "true", { expires });
    });
  }
}

export default PopupAction;
